<template>
  <div>
    <BaseLabel>
      Quote ID
    </BaseLabel>
    <Editor
      v-model="basicProperties.payload.quoteId"
      :input="true"
      class="mb-2"
    />
    <BaseLabel>
      Signed URL
    </BaseLabel>
    <Editor
      v-model="basicProperties.payload.signedUrl"
      :input="true"
      class="mb-2"
    />
    <BaseLabel>
      Environment
    </BaseLabel>
    <Editor
      v-model="basicProperties.payload.environment"
      :input="true"
      class="mb-2"
    />
  </div>
</template>

<script >
// libs
import { ref } from '@vue/composition-api';
// components
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
// composables
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  return {
    basicProperties
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
